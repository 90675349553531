<template>
	<div>
		<!--智能辅助并案-->
		<el-dialog title="智能辅助并案" :visible.sync="showznba" width="70%" :modal="false">
			<div style="height: 60vh;overflow-y: auto;">
				<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader"
					:showbm="true" :czwidth="120" :showpages="false">
					<template slot="btns" slot-scope="{ scope }">					
						<el-button @click="lookdetail(scope.row)" type="primary" size="mini"
							style="margin-bottom:10px;margin-left: 10px;">
							查看详情
						</el-button>						
					</template>
				</MyTable>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showznba=false">取 消</el-button>
				<el-button type="primary" @click="qrlian">一键并案</el-button>
			</span>
		</el-dialog>
		
		
		<!--详情-->
		<mydetail v-if="showdetail" :id="detail_id" :showdy="true" requsturl="/api/proposa/details"
			@closedetail="showdetail=false">
		</mydetail>
	</div>
	
</template>

<script>
	import mydetail from "@/components/detail_tc.vue";
	export default{
		components: {
			mydetail
		},
		data() {
			return{
				tableHeader: [
					{
						name: '标题',
						prop: 'name',
						width: '300'
					},
					{
						name: '提案类别',
						prop: 'lei_name',
						width: ''
					},
					{
						name: '提交人',
						prop: 'user_name',
						width: ''
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: ''
					}
				],
				tableData: [
					
				],
				showznba:false,
				rowdata:'',
				detail_id:'',
				showdetail:false
			}
		},
		created() {
			this.gettable()
		},
		methods:{
			qrlian(){
				this.$message.success('并案成功')
				this.showznba = false
			},
			gettable() {
				this.$post({
					url: '/api/proposa/index',
					params: {
						p: 1,
						size: 4
					}
				}).then((res) => {
					this.tableData = res.list
			
			// 		//回显打勾
			// 		let selectedlist = []
			// 		res.list.forEach((item, index) => {
			// 			if (item.xuan != 1) {
			// 				selectedlist.push(item)
			// 			}
			// 		})
			// 		this.$refs.zjlist.toggleSelection(selectedlist);
				})
			},
			lookdetail(item){
				this.rowdata = item
				this.detail_id = item.id
				this.showdetail = true
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .el-dialog__wrapper{
		z-index: 90 !important;
	}
	::v-deep .dialog-footer{
		display: flex;
		align-items: center;
		justify-content: flex-end !important;
	}
</style>